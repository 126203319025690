/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: hsl(var(--b2));
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
  opacity: 0.1;
}

*::-webkit-scrollbar-track {
  opacity: 0.1;
  width: 5px;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: hsl(var(--b2));
  border-radius: 14px;

  opacity: 0.1;
}
