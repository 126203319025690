@use '@angular/cdk';

@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */

// Angular CDK
@import '@angular/cdk/overlay-prebuilt.css';
@import '@angular/cdk/a11y-prebuilt.css';

// Angular CDK Textarea Autofill
@include cdk.text-field-autofill();

@import './style/main.scss';

@import './assets/fonts/fonts.scss';

html,
body {
  height: 100%;
}
body {
  margin: 0;
}
