@font-face {
  font-family: 'GoalMate';
  src: url('GoalMate - Light.woff2') format('woff2'),
    url('GoalMate - Light.woff') format('woff'),
    url('GoalMate - Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'GoalMate';
  src: url('GoalMate - Regular.woff2') format('woff2'),
    url('GoalMate - Regular.woff') format('woff'),
    url('GoalMate - Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'GoalMate';
  src: url('GoalMate - Medium.woff2') format('woff2'),
    url('GoalMate - Medium.woff') format('woff'),
    url('GoalMate - Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'GoalMate';
  src: url('GoalMate - Bold.woff2') format('woff2'),
    url('GoalMate - Bold.woff') format('woff'),
    url('GoalMate - Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  text-rendering: optimizeLegibility;
}

* {
  font-family: 'GoalMate';
}
