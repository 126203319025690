.range-c {
  appearance: none;
  -webkit-appearance: none;
  --range-shdw: theme(colors.base-content);
  @apply rounded-box overflow-hidden bg-transparent h-2 cursor-pointer;
  &:focus-visible::-webkit-slider-thumb {
    --focus-shadow: 0 0 0 6px theme(colors.base-100) inset,
      0 0 0 2rem var(--range-shdw) inset;
  }
  &:focus-visible::-moz-range-thumb {
    --focus-shadow: 0 0 0 6px theme(colors.base-100) inset,
      0 0 0 2rem var(--range-shdw) inset;
  }
  &::-webkit-slider-runnable-track {
    @apply rounded-box bg-base-content/10 h-3 w-full;
  }
  &::-moz-range-track {
    @apply rounded-box bg-base-content/10 h-3 w-full;
  }
  &::-webkit-slider-thumb {
    @apply rounded-box bg-base-content/10 relative h-3 w-3 border-none;
    appearance: none;
    -webkit-appearance: none;
    top: 50%;
    color: var(--range-shdw);
    background-color: var(--range-shdw);
    transform: translateY(-50%);
    --filler-size: 50rem;
    --filler-offset: 0.6rem;
    box-shadow: 0 0 0 3px var(--range-shdw) inset, var(--focus-shadow, 0 0),
      calc(var(--filler-size) * -1 - var(--filler-offset)) 0 0
        var(--filler-size);
  }
  &::-moz-range-thumb {
    @apply rounded-box bg-base-content/10 relative h-3 w-3 border-none;
    top: 50%;
    color: var(--range-shdw);
    --filler-size: 100rem;
    --filler-offset: 0.5rem;
    box-shadow: 0 0 0 3px var(--range-shdw) inset, var(--focus-shadow, 0 0),
      calc(var(--filler-size) * -1 - var(--filler-offset)) 0 0
        var(--filler-size);
  }
  &-primary {
    --range-shdw: theme(colors.primary);
  }
  &-secondary {
    --range-shdw: theme(colors.secondary);
  }
  &-accent {
    --range-shdw: theme(colors.accent);
  }
  &-success {
    --range-shdw: theme(colors.success);
  }
  &-warning {
    --range-shdw: theme(colors.warning);
  }
  &-info {
    --range-shdw: theme(colors.info);
  }
  &-error {
    --range-shdw: theme(colors.error);
  }
}
